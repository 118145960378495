// Colours
$bgmain: #ffffff;
$bgextra: #090909;
$bgaccent: #ff8600;

$accent1: #ff8600;
$accent2: #ffa203;
$accent3: #ec7c00;

$textcolour1: #ffffff;
$textcolour2: #5D5D5D;
$textcolour3: #979797;
