.Blog__postContainer {
    width: 100%;
    max-width: 1200px;
    padding: 30px 30px;
    @media (min-width: 576px) {
    }
    @media (min-width: 1024px) {
    }
    @media (min-width: 1400px) {
        padding: 50px 100px 150px 100px;
    }
}


.Blog__postTitle {
    color: #090909;
    font-size: 3.25rem;
    font-weight: 500;
    letter-spacing: -0.026em;
    margin: 0;
    padding-bottom: 50px;
}
@media (min-width: 1400px) {
    .Blog__postTitle {
        font-size: 3.75rem;
    }
}

.Blog__postContent {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 50px;
}
.Blog__postDate {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0 0 30px 0;
    color: #FF4E50;
    border-bottom: 1px solid #eae8e8;
    border-top: 1px solid #eae8e8;
    padding-bottom: 30px;
    padding-top: 30px;
}
.Blog__postImgWrapper {
    display: block;
    width: 100%;
    height: 500px;
}

.Blog__postImgWrapper img{
    max-width:100%;
    height:100%;
}

.Blog__postImg {

}
.Blog__postBackToBlog {
    text-decoration: none;
    color: #090909;
    font-size: 13px;
    margin-right: 10px;
    font-weight: 500;
    &:after {
        content: '';
        display: inline-block;
        width: 18px;
        height: 1px;
        background: #FF4E50;
        margin-bottom: 4px;
        margin-left: 16px;
        @media (min-width: 768px) {
            width: 24px;
            height: 2px;
            margin-left: 20px;
        }
    }

    @media (min-width: 768px) {
        font-size: 18px;
    }
}



.Blog__postNews {
    color: #FF4E50 !important;
    text-decoration: none;
    &:hover {
        color: #000 !important;
    }
}


.Blog__postAuthor {

}

.Blog__postContent p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;

    @media (min-width: 768px) {
        font-size: 16px;
        line-height: 30px;
    }
}

.Blog__postContent div {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;

    @media (min-width: 768px) {
        font-size: 16px;
        line-height: 30px;
    }
}

.Blog__postContent div span {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;

    @media (min-width: 768px) {
        font-size: 16px;
        line-height: 30px;
    }
}

.Blog__postContent h2 span {
    font-size: 20px;
    font-weight: 600;
}

.Blog__postContent h2 {
    font-size: 20px;
    font-weight: 600;
}

.Blog__postContent blockquote {
    border-left: 3px solid #FF4E50;
    padding-left: 30px;
    font-style: italic;
}