.PostsCRUD__title {
    display: block;
}
.PostsCRUD__text {
    display: block;
}
.PostsCRUD__author {
    display: block;
}
.PostsCRUD__img {
    display: block;
}
.PostsCRUD__submit {
    display: block;
}