.cursor {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid red;
  border-radius: 50%;
  background: #ffffff;
  opacity: 0.4;
  transform: translate(-50%, -50%);
  z-index: 9999;
  //transition: .1s;
  pointer-events: none;
}


