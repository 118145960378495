@import "fonts";
body {
  //overflow-x:hidden;
  font-family:"OpenSans", "Helvetica Neue", "Helvetica", sans-serif;
  letter-spacing:-0.025em;
  position:relative;
  margin: 0;
  //-webkit-font-smoothing: antialiased;
}
html * {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

html {
  font-size:12px;
  line-height: 1.15;
  //вид сколла
  scrollbar-color: #FF4E50 transparent;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    background: transparent;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #ff4e50;
    box-shadow: inset 0 0 2px #333;
    &:hover {
      background-color: #ef494b;
    }
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
  //-webkit-text-size-adjust: 100%;
  //-webkit-font-smoothing: antialiased;
}
@media (min-width: 576px) {
  html {
    font-size: 13px;
  }
}
@media (min-width: 1024px) {
  html {
    font-size: 14px;
  }
}
@media (max-resolution: 96dpi) and (min-width: 1400px) {
  html {
    font-size: 13px;
  }
}
@media (min-resolution: 100dpi) and (min-width: 1400px) {
    html {
        font-size: 14px;
    }
}
*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.display-none {
  display: none;

  @media (min-width: 1200px) {
    display: block;
  }
}
//body {
//  cursor: none;
//}

