@font-face {
    font-family:aktiv-grotesk;
    src:url(https://use.typekit.net/af/affdf5/00000000000000003b9b0328/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("woff2"),
    url(https://use.typekit.net/af/affdf5/00000000000000003b9b0328/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("woff"),
    url(https://use.typekit.net/af/affdf5/00000000000000003b9b0328/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3) format("opentype");
    font-weight:400;
    font-style:normal;
}
@font-face {
    font-family:aktiv-grotesk;
    src:url(https://use.typekit.net/af/b5ce2a/00000000000000003b9b032d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("woff2"),
    url(https://use.typekit.net/af/b5ce2a/00000000000000003b9b032d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("woff"),
    url(https://use.typekit.net/af/b5ce2a/00000000000000003b9b032d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("opentype");
    font-weight:700;
    font-style:normal;
}
@font-face {
    font-family:aktiv-grotesk;
    src:url(https://use.typekit.net/af/2223ac/00000000000000003b9b0326/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3) format("woff2"),
    url(https://use.typekit.net/af/2223ac/00000000000000003b9b0326/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3) format("woff"),
    url(https://use.typekit.net/af/2223ac/00000000000000003b9b0326/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3) format("opentype");
    font-weight:300;
    font-style:normal;
}
@font-face {
    font-family:aktiv-grotesk;
    src:url(https://use.typekit.net/af/67187a/00000000000000003b9b032a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3) format("woff2"),
    url(https://use.typekit.net/af/67187a/00000000000000003b9b032a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3) format("woff"),
    url(https://use.typekit.net/af/67187a/00000000000000003b9b032a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3) format("opentype");
    font-weight:500;
    font-style:normal;
}
@font-face {
    font-family:aktiv-grotesk;
    src:url(https://use.typekit.net/af/67187a/00000000000000003b9b032a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3) format("woff2"),
    url(https://use.typekit.net/af/67187a/00000000000000003b9b032a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3) format("woff"),
    url(https://use.typekit.net/af/67187a/00000000000000003b9b032a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3) format("opentype");
    font-weight:500;
    font-style:normal;
}
@font-face {
    font-family: 'OpenSans';
    src: url(./fonts/OpenSans-Regular.ttf) format('truetype');
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: 'OpenSans';
    src: url(./fonts/OpenSans-Italic.ttf) format('truetype');
    font-style: italic;
    font-weight: 400;
}
@font-face {
    font-family: 'OpenSans';
    src: url(./fonts/OpenSans-Light.ttf) format('truetype');
    font-style: normal;
    font-weight: 100;
}
@font-face {
    font-family: 'OpenSans';
    src: url(./fonts/OpenSans-LightItalic.ttf) format('truetype');
    font-style: italic;
    font-weight: 100;
}
@font-face {
    font-family: 'OpenSans';
    src: url(./fonts/OpenSans-SemiBold.ttf) format('truetype');
    font-style: normal;
    font-weight: 500;
}
@font-face {
    font-family: 'OpenSans';
    src: url(./fonts/OpenSans-SemiBoldItalic.ttf) format('truetype');
    font-style: italic;
    font-weight: 500;
}
@font-face {
    font-family: 'OpenSans';
    src: url(./fonts/OpenSans-Bold.ttf) format('truetype');
    font-style: normal;
    font-weight: 600;
}
@font-face {
    font-family: 'OpenSans';
    src: url(./fonts/OpenSans-BoldItalic.ttf) format('truetype');
    font-style: italic;
    font-weight: 600;
}
@font-face {
    font-family: 'OpenSans';
    src: url(./fonts/OpenSans-ExtraBold.ttf) format('truetype');
    font-style: normal;
    font-weight: 800;
}
@font-face {
    font-family: 'OpenSans';
    src: url(./fonts/OpenSans-ExtraBoldItalic.ttf) format('truetype');
    font-style: italic;
    font-weight: 800;
}


