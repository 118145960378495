.Blog__pagination {

}
.Blog__paginationItems {
    list-style-type:none;
    padding-left: 0;
}
.Blog__paginationItem {
    display: inline-block;
    margin-right: 10px;
}
.Blog__paginationLink:hover {
    background-color: #FF4E50;
    color: #ffffff;
}
.Blog__paginationLink {
    text-decoration: none;
    display: block;
    padding: 20px 25px;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #FF4E50;
    border-radius: 50%;
}
.page-active {
    color: #ffffff;
    background-color: #FF4E50;
    border-color: transparent;
    &:hover {
        background-color: #fff;
        color: #FF4E50;
        border: 1px solid #FF4E50;
    }
}
.page-notActive {
    color: #FF4E50;
}
