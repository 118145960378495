.Header {
    display: flex;
    align-items: center;
    height: 100px;
    width: 100%;
    background: #FFF;
    padding: 0 30px;
    margin-bottom: 20px;

    @media (min-width: 360px) {
        padding: 0 30px;
    }
    @media (min-width: 768px) {
        padding: 0 50px;
    }
    @media (min-width: 1400px) {
        padding: 0 90px;
    }
}

.Header__logo {
    margin-right: 70px;
    width: 90px;
    background: no-repeat center url(img/bkey-logo-light.svg);
    z-index: 999;
    height: 100px;
}

.Header__menu {
    display: none;

    @media (min-width: 1200px) {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
}

.Header__navigationItems {
    list-style-type: none;
    padding: 0;
    font-size: 16px;
}

.Header__navigationItem {
    display: inline;
    margin-right: 50px;
}

.active{
    color: #000 !important;
}

.Header__navigationLink {
    display: inline-block;
    color: rgba(0,0,0,0.6);
    text-decoration: none;
    transition: .2s;
    &:hover {
        color:rgba(0,0,0,0.8);
    }
}

.Header__socialItems {
    list-style: none;
    display: none;

    @media (min-width: 1200px) {
        display: flex;
    }
    :last-child {
        padding: 0;
    }
}

.Header__socialItem {
    padding-right: 40px;
}

.Header__socialIcon {
    color: #ff4e50;
    font-size: 16px;
}

